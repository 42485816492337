<template>
  <div class="custom-container">
    <button class="custom-back-button" @click="goToMain">←</button>

    <div v-if="isModalOpen" class="custom-modal-overlay" @click.self="closeModal">
      <div class="custom-modal-content">
        <button class="custom-close-button" @click="closeModal">X</button>
        <h2>{{ modalContent.title }}</h2>
        <p>{{ modalContent.description }}</p>

        <label class="custom-file-upload">
          Выберите файл
          <input type="file" @change="handleFileUpload" accept=".docx" />
        </label>

        <p v-if="modalContent.fileName">Загруженный файл: {{ modalContent.fileName }}</p>

        <div v-if="modalContent.file" class="file-actions">
          <a class="download-button" :href="modalContent.file" :download="modalContent.fileName">Скачать</a>
          <button class="delete-button" @click="deleteFile">Удалить</button>
        </div>
      </div>
    </div>

    <div v-for="(lesson, index) in lessons" :key="index" class="custom-lessons" @click="openModal(lesson, index)">
      {{ lesson.title }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isModalOpen: false,
      modalContent: {},
      lessons: [
        { title: 'Урок 1', description: 'Описание для урока 1', file: null, fileName: null },
        { title: 'Урок 2', description: 'Описание для урока 2', file: null, fileName: null },
        { title: 'Урок 3', description: 'Описание для урока 3', file: null, fileName: null },
        { title: 'Урок 4', description: 'Описание для урока 4', file: null, fileName: null },
        { title: 'Урок 5', description: 'Описание для урока 5', file: null, fileName: null },
      ],
      currentLessonIndex: null,
    };
  },
  methods: {
    openModal(lesson, index) {
      this.modalContent = lesson;
      this.currentLessonIndex = index;
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.currentLessonIndex = null;
    },
    goToMain() {
      this.$router.push("/main");
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const fileURL = URL.createObjectURL(file);
        this.lessons[this.currentLessonIndex].file = fileURL;
        this.lessons[this.currentLessonIndex].fileName = file.name;
        this.modalContent.file = fileURL;
        this.modalContent.fileName = file.name;
        this.saveLessonsToLocalStorage();
      }
    },
    deleteFile() {
      this.modalContent.file = null;
      this.modalContent.fileName = null;
      this.lessons[this.currentLessonIndex].file = null;
      this.lessons[this.currentLessonIndex].fileName = null;
      this.saveLessonsToLocalStorage();
    },
    saveLessonsToLocalStorage() {
      localStorage.setItem('kmjLessons', JSON.stringify(this.lessons));
    },
    loadLessonsFromLocalStorage() {
      const savedLessons = localStorage.getItem('kmjLessons');
      if (savedLessons) {
        this.lessons = JSON.parse(savedLessons).slice(0, 5);
      }
    }
  },
  mounted() {
    this.loadLessonsFromLocalStorage();
  }
};
</script>

<style scoped>
.custom-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  position: relative;
  background-image: url('https://png.pngtree.com/background/20210710/original/pngtree-fresh-hand-drawn-book-dot-advertising-background-picture-image_980635.jpg'); /* Добавьте здесь изображения книг */
  background-size: cover; /* Масштабирует изображения, чтобы они полностью покрывали фон */
  background-position: center; /* Центрирует изображения */ 
}

.custom-lessons {
  cursor: pointer;
  width: 150px;
  height: 50px;
  border: 2px solid #333;
  border-radius: 15px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6A5ACD;
  color: white;
  transition: background-color 0.3s, transform 0.3s;
}

.custom-lessons:hover {
  background-color: #5b4c91;
  transform: scale(1.05);
}

.custom-back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
  padding: 10px 15px;
  border: none;
  border-radius: 25px;
  font-size: 20px;
  color: white;
  background: linear-gradient(135deg, #5b4c91, #6a5acd);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.custom-back-button:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.custom-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.custom-close-button {
  color: black;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 16px;
}

.custom-file-upload {
  display: inline-block;
  padding: 10px 15px;
  border: 2px solid #6A5ACD;
  border-radius: 5px;
  background-color: #fff;
  color: #6A5ACD;
  cursor: pointer;
  margin: 10px 0;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
}

.custom-file-upload:hover {
  background-color: #6A5ACD;
  color: white;
  transform: scale(1.05);
}

.custom-file-upload input[type="file"] {
  display: none;
}

.file-actions {
  margin-top: 10px;
}

.download-button, .delete-button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.3s;
  margin: 5px;
}

.download-button {
  background-color: #4CAF50;
  color: white;
}

.download-button:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

.delete-button {
  background-color: #f44336;
  color: white;
}

.delete-button:hover {
  background-color: #d32f2f;
  transform: scale(1.05);
}

/* Адаптивные стили для мобильных устройств */
@media (max-width: 768px) {
  .custom-container {
    flex-direction: column;
    padding: 20px;
  }

  .custom-lessons {
    width: 100px;
    height: 40px;
    font-size: 14px;
    margin: 5px;
  }

  .custom-modal-content {
    width: 90%;
    padding: 15px;
  }

  .custom-back-button {
    padding: 8px 10px;
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .custom-lessons {
    width: 80px;
    height: 35px;
    font-size: 12px;
  }

  .custom-back-button {
    padding: 6px 8px;
    font-size: 16px;
  }
}
</style>
