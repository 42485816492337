<template>
  <div class="ktp-container">
    <!-- Кнопка возврата на главную страницу -->
    <button class="custom-back-button" @click="goToMain">←</button>

    <h2>КТП Документ</h2>

    <div class="document-actions">
      <!-- Кнопка для скачивания документа -->
      <button v-if="documentUrl" @click="downloadDocument" class="download-button">Скачать</button>
      <!-- Кнопка для удаления документа -->
      <button v-if="documentUrl" @click="deleteDocument" class="delete-button">Удалить</button>
    </div>

    <!-- Отображение названия файла -->
    <p v-if="fileName" class="file-name">{{ fileName }}</p>

    <!-- Поле для добавления заметок -->
    <div v-if="documentUrl" class="notes-section">
      <h3>Заметки</h3>
      <textarea v-model="notes" @input="saveNotes" placeholder="Добавьте заметку..."></textarea>
    </div>

    <!-- Форма для загрузки нового документа -->
    <label class="custom-file-upload" v-if="!documentUrl">
      Выберите файл
      <input type="file" @change="uploadDocument" accept=".doc, .docx" />
    </label>
  </div>
</template>

<script>
export default {
  data() {
    return {
      documentUrl: localStorage.getItem("ktpDocument") || null,
      fileName: localStorage.getItem("ktpFileName") || null,
      notes: localStorage.getItem("ktpNotes") || "", // загружаем заметки
    };
  },
  methods: {
    saveDocumentUrl() {
      localStorage.setItem("ktpDocument", this.documentUrl);
      localStorage.setItem("ktpFileName", this.fileName);
      localStorage.setItem("ktpNotes", this.notes); // сохраняем заметки
    },
    deleteDocument() {
      localStorage.removeItem("ktpDocument");
      localStorage.removeItem("ktpFileName");
      localStorage.removeItem("ktpNotes");
      this.documentUrl = null;
      this.fileName = null;
      this.notes = ""; // очищаем заметки
    },
    uploadDocument(event) {
      const file = event.target.files[0];
      if (file) {
        this.fileName = file.name;
        this.documentUrl = URL.createObjectURL(file);
        this.saveDocumentUrl();
      }
    },
    downloadDocument() {
      if (this.documentUrl) {
        const link = document.createElement("a");
        link.href = this.documentUrl;
        link.download = this.fileName || "ktp-document.docx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    saveNotes() {
      localStorage.setItem("ktpNotes", this.notes); // сохраняем заметки при изменении
    },
    goToMain() {
      this.$router.push("/main");
    },
  },
};
</script>

<style scoped>
.ktp-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  padding: 10px; /* Добавляем отступы для лучшего отображения на мобильных устройствах */
  background-image: url('https://png.pngtree.com/background/20210710/original/pngtree-fresh-hand-drawn-book-dot-advertising-background-picture-image_980635.jpg'); /* Добавьте здесь изображения книг */
  background-size: cover; /* Масштабирует изображения, чтобы они полностью покрывали фон */
  background-position: center; /* Центрирует изображения */ 
}

.custom-back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
  padding: 10px 15px;
  border: none;
  border-radius: 25px;
  font-size: 20px;
  color: white;
  background: linear-gradient(135deg, #5b4c91, #6a5acd);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.custom-back-button:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.document-actions {
  display: flex;
  flex-direction: column; /* Изменяем направление на колонку для мобильных устройств */
  gap: 10px;
  margin-bottom: 10px;
}

.download-button,
.delete-button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  width: 100%; /* Ширина кнопок 100% на мобильных устройствах */
}

.download-button {
  background-color: #4CAF50;
  color: white;
}

.download-button:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

.delete-button {
  background-color: #f44336;
  color: white;
}

.delete-button:hover {
  background-color: #d32f2f;
  transform: scale(1.05);
}

.file-name {
  margin-top: 5px;
  font-style: italic;
  color: #333;
}

.notes-section {
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
  text-align: left;
}

.notes-section h3 {
  color: #333;
  margin-bottom: 5px;
}

.notes-section textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  resize: vertical;
}

/* Медиа-запросы для мобильных устройств */
@media (max-width: 600px) {
  .ktp-container {
    padding: 5px; /* Уменьшаем отступы на мобильных устройствах */
  }

  .custom-back-button {
    font-size: 18px; /* Уменьшаем размер кнопки на мобильных устройствах */
  }

  .notes-section textarea {
    font-size: 14px; /* Уменьшаем размер шрифта в текстовом поле */
  }
}
</style>
