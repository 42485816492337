<template>
  <div id="main">
    <title>Winner-db</title>
    <div class="container-blocks">
      <router-link to="/ktp" class="block ktp-block">
        <h4>КТП</h4>
      </router-link>
      <router-link to="/kmj" class="block kmj-block">
        <h4>ҚМЖ</h4>
      </router-link>
      <router-link to="/student" class="block student-block">
        <h4>Оқушыға</h4>
      </router-link>
      <router-link to="/bjbtjb" class="block bjb-block">
        <h4>БЖБ/ТЖБ</h4>
      </router-link>
      <a href="https://chatgpt.com" target="_blank" class="block ai-block">
        <h4>AI</h4>
      </a>
    </div>
  </div>
</template>

<style scoped>
#main {
  height: 100vh; 
  font-size: 26px;
  background-color: #f4f4f9;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('https://png.pngtree.com/background/20210710/original/pngtree-fresh-hand-drawn-book-dot-advertising-background-picture-image_980635.jpg'); /* Добавьте здесь изображения книг */
  background-size: cover; /* Масштабирует изображения, чтобы они полностью покрывали фон */
  background-position: center; /* Центрирует изображения */

}

.container-blocks {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  max-width: 1200px;
  justify-content: center;
}

.block {
  width: 250px;
  height: 250px;
  border-radius: 20px;
  cursor: pointer;
  text-decoration: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  transition: transform 0.3s, box-shadow 0.3s; 
  font-weight: bold;
  font-size: 22px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(135deg, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.1));
}

.ktp-block {
  background: linear-gradient(135deg, #6A5ACD, #483D8B);
}

.kmj-block {
  background: linear-gradient(135deg, #FF6347, #FF4500);
}

.student-block {
  background: linear-gradient(135deg, #20B2AA, #008B8B);
}

.bjb-block {
  background: linear-gradient(135deg, #3CB371, #2E8B57);
}

.ai-block {
  background: linear-gradient(135deg, #FFD700, #FFA500);
}

.block:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}

.block h4 {
  margin: 0;
  color: #fff;
  text-transform: uppercase;
}

/* Адаптивные стили для мобильных устройств */
@media (max-width: 768px) {
  .container-blocks {
    gap: 15px;
  }

  .block {
    width: 120px;
    height: 120px;
    font-size: 18px;
    margin: 10px;
  }
}

@media (max-width: 480px) {
  .container-blocks {
    gap: 10px;
  }

  .block {
    width: 100px;
    height: 100px;
    font-size: 16px;
    margin: 8px;
  }

  #main {
    font-size: 20px;
  }
}
</style>

<script>
export default {
  name: "App",
};
</script>
