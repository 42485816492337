import { createApp } from 'vue';
import App from './App.vue';
import { createRouter, createWebHistory } from 'vue-router'; 
import MainBlock from './blocks/MainBlock.vue';
import KtpBlock from './blocks/KtpBlock.vue';
import KmjBlock from './blocks/KmjBlock.vue';
import StdBlock from './blocks/StdBlock.vue';
import BjbBlock from './blocks/BjbBlock.vue';

const routes = [
  { 
    path: '/', 
    redirect: '/main',
  },
  { 
    path: '/ktp', 
    name: 'ktp', 
    component: KtpBlock 
  },
  { 
    path: '/main', 
    name: 'main', 
    component: MainBlock 
  },
  { 
    path: '/kmj', 
    name: 'kmj', 
    component: KmjBlock 
  },
  { 
    path: '/student', 
    name: 'student', 
    component: StdBlock 
  },
  { 
    path: '/bjbtjb', 
    name: 'bjbtjb', 
    component: BjbBlock 
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const app = createApp(App);
app.use(router);
app.mount('#app');
