<template>
    <div class="bjbtjb-container">
      <!-- Кнопка возврата на главную страницу -->
      <button class="custom-back-button" @click="goToMain">←</button>
  
      <!-- Два блока "БЖБ" и "ТЖБ" -->
      <div class="bjbtjb-block" @click="openModal('БЖБ')">БЖБ</div>
      <div class="bjbtjb-block" @click="openModal('ТЖБ')">ТЖБ</div>
  
      <!-- Модальное окно -->
      <div v-if="isModalOpen" class="modal-overlay" @click.self="closeModal">
        <div class="modal-content">
          <button class="close-button" @click="closeModal">X</button>
          <h2>{{ modalTitle }}</h2>
  
          <!-- Форма для загрузки файла -->
          <label class="custom-file-upload" v-if="!file">
            Выберите файл
            <input type="file" @change="handleFileUpload" accept=".doc, .docx" />
          </label>
  
          <!-- Название загруженного файла -->
          <p v-if="fileName">Загруженный файл: {{ fileName }}</p>
  
          <!-- Кнопки для скачивания и удаления -->
          <div v-if="file" class="file-actions">
            <a class="download-button" :href="file" :download="fileName">Скачать</a>
            <button class="delete-button" @click="deleteFile">Удалить</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isModalOpen: false,
        modalTitle: '',
        file: null,
        fileName: null,
      };
    },
    methods: {
      goToMain() {
        this.$router.push('/main');
      },
      openModal(title) {
        this.modalTitle = title;
        this.isModalOpen = true;
      },
      closeModal() {
        this.isModalOpen = false;
        this.file = null;
        this.fileName = null;
      },
      handleFileUpload(event) {
        const uploadedFile = event.target.files[0];
        if (uploadedFile) {
          this.file = URL.createObjectURL(uploadedFile);
          this.fileName = uploadedFile.name;
        }
      },
      deleteFile() {
        this.file = null;
        this.fileName = null;
      },
    },
  };
  </script>
  
  <style scoped>
  .bjbtjb-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100vh;
    background-image: url('https://png.pngtree.com/background/20210710/original/pngtree-fresh-hand-drawn-book-dot-advertising-background-picture-image_980635.jpg'); /* Добавьте здесь изображения книг */
    background-size: cover; /* Масштабирует изображения, чтобы они полностью покрывали фон */
    background-position: center; /* Центрирует изображения */ 
  }
  
  .custom-back-button {
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
    padding: 10px 15px;
    border: none;
    border-radius: 25px;
    font-size: 20px;
    color: white;
    background: linear-gradient(135deg, #5b4c91, #6a5acd);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .custom-back-button:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  }
  
  .bjbtjb-block {
    cursor: pointer;
    width: 80%;
    max-width: 300px; /* Ограничиваем максимальную ширину */
    height: 80px;
    border: 2px solid #333;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #6A5ACD;
    border-radius: 10px;
    color: white;
    transition: transform 0.3s;
  }
  
  .bjbtjb-block:hover {
    transform: scale(1.05);
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    position: relative;
    text-align: center;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .custom-file-upload {
    padding: 10px 15px;
    border: 2px solid #6A5ACD;
    border-radius: 5px;
    background-color: #fff;
    color: #6A5ACD;
    cursor: pointer;
    display: inline-block;
  }
  
  .custom-file-upload input[type="file"] {
    display: none;
  }
  
  .file-actions {
    display: flex;
    gap: 10px;
    justify-content: center; /* Центрируем кнопки */
    margin-top: 15px; /* Добавляем отступ сверху */
  }
  
  .download-button,
  .delete-button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    min-width: 100px; /* Задаем минимальную ширину, чтобы кнопки были одинакового размера */
    text-align: center; /* Центрируем текст на кнопках */
  }
  
  .download-button {
    background-color: #4CAF50;
  }
  
  .delete-button {
    background-color: #f44336;
  }
  
  /* Медиа-запросы для мобильных устройств */
  @media (max-width: 600px) {
    .bjbtjb-block {
      width: 90%; /* Увеличиваем ширину на мобильных устройствах */
      height: 60px; /* Уменьшаем высоту для мобильных устройств */
      font-size: 18px; /* Увеличиваем размер шрифта для мобильных устройств */
    }
  
    .modal-content {
      width: 95%; /* Увеличиваем ширину модального окна для мобильных устройств */
    }
  
    .custom-back-button {
      font-size: 18px; /* Уменьшаем размер кнопки на мобильных устройствах */
    }
  
    .download-button,
    .delete-button {
      font-size: 14px; /* Уменьшаем размер текста на кнопках */
      padding: 8px 12px; /* Уменьшаем отступы на кнопках */
    }
  }
  </style>
  