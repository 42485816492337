<template>
  <div id="app">
      <router-view/>
  </div>
</template>

<style lang="scss">
  * {
      background: linear-gradient(135deg, #ece9e6);
      scroll-behavior: smooth;
  }


</style>


<script>      
import MainBlock from './blocks/MainBlock.vue';  

export default {
  name: 'app',
  components: {
      MainBlock
  }
}

</script>
