<template>
  <div class="student-container">
    <!-- Кнопка возврата на главную страницу -->
    <button class="custom-back-button" @click="goToMain">←</button>

    <!-- Модальное окно -->
    <div v-if="isModalOpen" class="custom-modal-overlay" @click.self="closeModal">
      <div class="custom-modal-content">
        <button class="custom-close-button" @click="closeModal">X</button>
        <h2>{{ modalContent.title }}</h2>

        <!-- Отображение задач с разными ссылками -->
        <ul>
          <li v-for="(task, index) in modalContent.tasks" :key="index">
            <a :href="task.link" target="_blank">{{ task.name }}</a>
          </li>
        </ul>

        <!-- Раздел "home work" с возможностью добавить ссылку -->
        <p class="homework">
          <a :href="modalContent.homework.link" target="_blank">Home Work</a>
        </p>
      </div>
    </div>

    <!-- Блоки students -->
    <div
      v-for="(student, index) in students"
      :key="index"
      class="student-circle"
      @click="openModal(student)"
    >
      {{ student.title }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isModalOpen: false,
      modalContent: {},
      students: [
        {
          title: 'Lesson 1',
          tasks: [
            { name: 'Task 1', link: 'https://youtu.be/Gm_hYf3KDEU?si=JHCstLVJNTeQWTay' },
            { name: 'Task 2', link: 'https://i.pinimg.com/736x/b3/8d/ba/b38dba2b899f0bc9f07107b381fad9e1.jpg' },
            { name: 'Task 3', link: 'https://learningapps.org/view3250159' },
          ],
          homework: { link: 'https://www.liveworksheets.com/w/en/english-second-language-esl/1245067' },
        },
        {
          title: 'Lesson 2',
          tasks: [
            { name: 'Task 1', link: 'https://www.youtube.com/watch?v=Yt8GFgxlITs' },
            { name: 'Task 2', link: 'https://learnenglishkids.britishcouncil.org/grammar-vocabulary/word-games/numbers-1-10' },
          ],
          homework: { link: 'https://wordwall.net/ru/resource/28900883/english/numbers-0-10-find-the-match-myteachingstuff' },
        },
        {
          title: 'Lesson 3',
          tasks: [
            { name: 'Task 1', link: 'https://learnenglishkids.britishcouncil.org/grammar-vocabulary/word-games/colours' },
            { name: 'Task 2', link: 'https://www.youtube.com/watch?v=SLZcWGQQsmg' },
          ],
          homework: { link: 'https://learnenglishkids.britishcouncil.org/sites/kids/files/attachment/colouring-rainbow.pdf' },
        },
        {
          title: 'Lesson 4',
          tasks: [
            { name: 'Task 1', link: 'https://www.youtube.com/watch?v=7i211NeJZRQ' },
            { name: 'Task 2', link: 'https://learnenglishkids.britishcouncil.org/sites/kids/files/attachment/flashcards-classroom-objects.pdf' },
          ],
          homework: { link: 'https://wordwall.net/ru/resource/52827998/english/kids-box-1-unit-2-my-school-find-the-match' },
        },
        {
          title: 'Lesson 5',
          tasks: [
            { name: 'Task 1', link: 'https://www.eslprintables.com/previews/531023_1-The_school_bag.jpg' },
            { name: 'Task 2', link: 'https://wordwall.net/ru/resource/1933985/objects-in-jens-school-bag' },
            { name: 'Task 3', link: 'https://quizizz.com/admin/quiz/6026234708c4fd001cf0f7e4/my-school-bag' },
          ],
          homework: { link: 'https://www.eslprintables.com/vocabulary_worksheets/school/In_my_school_bag_I_have__773737/#google_vignette' },
        },
      ],
    };
  },
  methods: {
    openModal(student) {
      this.modalContent = student;
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    goToMain() {
      this.$router.push('/main');
    },
  },
};
</script>

<style scoped>
.student-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
  min-height: 100vh;
  background-image: url('https://png.pngtree.com/background/20210710/original/pngtree-fresh-hand-drawn-book-dot-advertising-background-picture-image_980635.jpg'); /* Добавьте здесь изображения книг */
  background-size: cover; /* Масштабирует изображения, чтобы они полностью покрывали фон */
  background-position: center; /* Центрирует изображения */ 
}

.student-circle {
  cursor: pointer;
  width: 80px; /* Уменьшенный размер для мобильных */
  height: 80px; /* Уменьшенный размер для мобильных */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  background: linear-gradient(135deg, #6a5acd, #483d8b);
  color: #ffffff;
  font-weight: bold;
  font-size: 16px; /* Уменьшенный размер текста для мобильных */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s, box-shadow 0.3s;
}

.student-circle:hover {
  transform: scale(1.05); /* Уменьшенное масштабирование для мобильных */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.custom-back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
  padding: 10px;
  border: none;
  border-radius: 25px;
  font-size: 18px; /* Уменьшенный размер для мобильных */
  color: white;
  background: linear-gradient(135deg, #5b4c91, #6a5acd);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.custom-back-button:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.custom-modal-content {
  background: #ffffff;
  padding: 20px;
  border-radius: 12px;
  width: 90%; /* Увеличенный размер для мобильных */
  max-width: 400px;
  text-align: center;
  position: relative;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3);
}

.custom-close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  border: none;
  background: none;
  color: #333;
  font-size: 18px;
}

ul {
  list-style: none;
  padding: 0;
}

li a {
  text-decoration: none;
  font-size: 16px; /* Уменьшенный размер текста для мобильных */
  color: #483d8b;
  font-weight: bold;
  transition: color 0.2s;
}

li a:hover {
  color: #6a5acd;
}

.homework {
  margin-top: 20px;
}

.homework a {
  font-size: 18px; /* Уменьшенный размер текста для мобильных */
  color: #483d8b;
  font-weight: bold;
  text-decoration: none;
}

.homework a:hover {
  color: #6a5acd;
}

/* Медиазапросы для мобильных устройств */
@media (max-width: 600px) {
  .student-container {
    padding: 10px; /* Уменьшенное пространство */
  }

  .student-circle {
    width: 70px; /* Уменьшенный размер для мобильных */
    height: 70px; /* Уменьшенный размер для мобильных */
    font-size: 14px; /* Уменьшенный размер текста для мобильных */
  }

  .custom-back-button {
    padding: 8px; /* Уменьшенное пространство для кнопки */
    font-size: 16px; /* Уменьшенный размер для мобильных */
  }

  .custom-modal-content {
    padding: 15px; /* Уменьшенное пространство для модального окна */
  }

  .custom-close-button {
    font-size: 16px; /* Уменьшенный размер кнопки закрытия для мобильных */
  }
}
</style>
